import { inject } from '@angular/core';
import { filter, firstValueFrom, switchMap, take } from 'rxjs';
import { AppState } from '../../store/app.state';
import { UserState, UserType } from '../../store/user.state';
import { AppService } from '../../services/app.service';
import { CanActivateFn } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { AuthenticationModalPage } from '../../modals/authentication/authentication-modal.page';
import { NavigationService } from '../../services/navigation.service';
import { ComponentRef } from '@ionic/core';
import { ModalService } from '../../services/modal.service';

export const authenticationPageGuard: CanActivateFn = async () => {
  const appState = inject(AppState);
  const userState = inject(UserState);
  const appService = inject(AppService);
  const modalService = inject(ModalService);
  const navigationService = inject(NavigationService);
  const profileState = inject(ProfileState);

  const user = await firstValueFrom(
    appState.appReady$.pipe(
      filter(appReady => appReady),
      take(1),
      switchMap(() => userState.user$),
    ),
  );

  if (user.userType === UserType.MOYA) {
    void navigationService.navigateRoot('home');
    return false;
  }

  const params = new URL(window.location.toString()).searchParams;
  const jwt = params.get('jwt');
  const forgotPasswordToken = params.get('token');

  let componentProps = null;

  // If the url is the confirmation email url, we check that the jwt match the logged in jwt
  if (window.location.href.includes('/confirmation-email')) {
    if (jwt && matchJwt(jwt, userState.user$.value.id)) {
      componentProps = { confirmEmailToken: jwt };
    } else {
      void navigationService.navigateRoot('home');
      return false;
    }
  }

  if (window.location.href.includes('/forgot-password') && forgotPasswordToken) {
    componentProps = { forgotPasswordToken };
  }

  if (appService.isMobile$.value) {
    return true;
  } else {
    // Open authentication modal
    void navigationService.navigateRoot('/home');
    const loginCreateAccountModal = await modalService.create({
      component: AuthenticationModalPage as ComponentRef,
      cssClass: 'wr-modal authentication',
      componentProps: componentProps || {},
    });

    await loginCreateAccountModal.present();

    // Force go to profile creation
    await loginCreateAccountModal.onDidDismiss().then(() => {
      if (userState.user$.value.userType !== UserType.GUEST && !profileState.currentProfile$.value) {
        void navigationService.navigateRoot(profileState.profiles$.value.length > 1 ? '/profile/select' : '/profile/create', {
          state: { hideHeader: true },
        });
      }
    });

    return false;
  }
};

const matchJwt = (urlJwt: string, storedUserId: string): boolean => {
  try {
    const urlUserId = JSON.parse(atob(urlJwt.split('.')[1]))?.userId;

    if (!storedUserId) {
      return false;
    }

    return urlUserId === storedUserId;
  } catch {
    return false;
  }
};
