<div class="create-account-login-container" data-cy="authentication-desktop-modal">
  <div (click)="closeModal(false)" class="close-modal">
    <div class="close-btn"></div>
  </div>
  @if ((authenticationStepService.currentStep$ | async)?.title) {
    <div class="title" [class.centered]="shouldCenterTitle((authenticationStepService.currentStep$ | async)?.step)">
      @if ((authenticationStepService.currentStep$ | async)?.showBack) {
        <div class="previous arabic-transform" (click)="navigateBack()"></div>
      }
      <div>{{ (authenticationStepService.currentStep$ | async)?.title }}</div>
    </div>
  }

  <app-authentication
    (closePage)="closeModal()"
    [confirmEmailToken]="confirmEmailToken"
    [forgotPasswordToken]="forgotPasswordToken"
    [googleOAuth]="googleOAuth"/>
</div>
