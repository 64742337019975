import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShelfType } from '../../services/books.service';
import { fadeInOut } from '../../utils/animations';
import { HeaderComponent } from '../../components/header/header.component';
import { SearchComponent } from './component/search/search.component';
import { BookShelfDrawerComponent } from '../../components/book-shelf/book-shelf-drawer/book-shelf-drawer.component';
import { BookCategoriesShelvesComponent } from '../../components/book-categories-shelves/book-categories-shelves.component';
import { IonContent } from '@ionic/angular/standalone';
import { AsyncPipe } from '@angular/common';
import { ProfileState } from '../../store/profile.state';
import { TipsComponent } from './component/tips/tips.component';
import { VroomTipsService } from '../../services/vroom-tips.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  animations: [fadeInOut],
  imports: [HeaderComponent, SearchComponent, BookShelfDrawerComponent, BookCategoriesShelvesComponent, IonContent, AsyncPipe, TipsComponent],
})
export class HomePage {
  constructor(
    public profileState: ProfileState,
    public vroomTipsService: VroomTipsService,
  ) {}

  isSearchFocused = false;

  protected readonly shelfType = ShelfType;

  searchFocused(focused: boolean): void {
    this.isSearchFocused = focused;
  }
}
