import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fadeInOut } from '../../utils/animations';
import { AuthenticationComponent, AuthenticationSteps } from '../../components/authentication/authentication.component';
import { AuthenticationStepService } from '../../components/authentication/authentication-step.service';
import { filter, take } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { ModalService } from '../../services/modal.service';
import { UserState, UserType } from '../../store/user.state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-authentication-modal',
  templateUrl: './authentication-modal.page.html',
  styleUrls: ['./authentication-modal.page.scss'],
  animations: [fadeInOut],
  imports: [AuthenticationComponent, AsyncPipe],
  encapsulation: ViewEncapsulation.None,
})
export class AuthenticationModalPage implements OnInit {
  forgotPasswordToken?: string; // Forgot password token, if provided
  confirmEmailToken?: string; // confirmation link token, if provided

  // Google oauth2
  googleOAuth?: {
    isOauth: boolean;
    state: string;
    code: string;
  };

  canDismiss = false;

  constructor(
    public authenticationStepService: AuthenticationStepService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private userState: UserState,
  ) {}

  closeModal(ignoreRedirect = true): void {
    void this.modalService.dismiss({ ignoreRedirect });
  }

  ngOnInit(): void {
    this.authenticationStepService.initialized$
      .pipe(
        filter(i => i),
        take(1),
      )
      .subscribe(() => {
        this.ref.detectChanges();
      });
  }

  shouldCenterTitle(step: AuthenticationSteps | undefined): boolean {
    switch (step) {
      case AuthenticationSteps.EMAIL:
      case AuthenticationSteps.RESET_PASSWORD:
      case AuthenticationSteps.RESET_PASSWORD_CONFIRM:
      case AuthenticationSteps.EMAIL_VALIDATED:
      case AuthenticationSteps.FORGOT_PASSSWORD_CONFIRM:
      case AuthenticationSteps.ACCOUNT_CREATED:
      case AuthenticationSteps.EMAIL_NOT_VALIDATED:
        return true;
      default:
        return false;
    }
  }

  navigateBack(): void {
    switch (this.authenticationStepService.currentStep$!.value.step) {
      case AuthenticationSteps.EMAIL: // mobile only
        void this.modalService.dismiss();
        break;
      case undefined:
      case AuthenticationSteps.LOGIN:
      case AuthenticationSteps.CREATE_ACCOUNT:
        this.authenticationStepService.setStep(AuthenticationSteps.EMAIL);
        break;
      case AuthenticationSteps.FORGOT_PASSWORD:
        if (this.userState.user$.value.userType === UserType.GUEST) {
          this.authenticationStepService.setStep(AuthenticationSteps.LOGIN);
        } else {
          void this.modalService.dismiss();
        }
    }

    this.ref.detectChanges();
  }
}
