import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IonApp, IonMenu, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { UserLocationState } from './store/user-location.state';
import { AppState } from './store/app.state';
import { UserState } from './store/user.state';
import { DefaultProjectState } from './store/default-project.state';
import { ProfileState } from './store/profile.state';
import { BookReaderSettingsState } from './store/book-reader-settings.state';
import { LanguageService } from './services/language.service';
import { LanguageState } from './store/language.state';
import { BookFiltersState } from './store/book-filters.state';
import { AppService } from './services/app.service';
import { ActivityCategoriesState } from './store/activity-categories.state';
import { BooksService } from './services/books.service';
import { SplashScreenService } from './services/splash-screen.service';
import { ProfileService } from './services/profile.service';
import { FlagsState } from './store/flags.state';
import { Feature, LoggerService } from './services/logger.service';
import { NetworkService } from './services/network.service';
import { SplashScreenComponent } from './components/web-splash-screen/splash-screen.component';
import { MenuComponent } from './components/menu/menu.component';
import { IsMobileResolutionDirective } from './directives/is-mobile-resolution.directive';
import { AsyncPipe } from '@angular/common';
import { DeveloperToolsService } from './services/developer-tools.service';
import { SearchState } from './store/search.state';
import { BannerService } from './services/banner.service';
import { BookShelfSettingsState } from './store/book-shelf-settings.state';
import { RouterModule } from '@angular/router';
import { AnalyticsService } from './services/analytics/analytics.service';
import { DeepLinkService } from './services/deep-link.service';
import { MixpanelService } from './services/mixpanel.service';
import { QueryParamsService } from './services/query-params.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
  imports: [RouterModule, IonMenu, IonApp, SplashScreenComponent, IonRouterOutlet, MenuComponent, IsMobileResolutionDirective, AsyncPipe],
})
export class AppComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

  constructor(
    public appState: AppState,
    public appService: AppService,
    public languageService: LanguageService,
    private platform: Platform,
    private bookReaderSettingsState: BookReaderSettingsState,
    private userLocationState: UserLocationState,
    private userState: UserState,
    private defaultProjectState: DefaultProjectState,
    private profileState: ProfileState,
    private languageState: LanguageState,
    private bookFiltersState: BookFiltersState,
    private activityCategories: ActivityCategoriesState,
    private flagState: FlagsState,
    private searchState: SearchState,
    private bookShelfSettingsState: BookShelfSettingsState,
    private booksService: BooksService,
    private splashScreenService: SplashScreenService,
    private profileService: ProfileService,
    private loggerService: LoggerService,
    private networkService: NetworkService,
    private developerToolsService: DeveloperToolsService,
    private bannerService: BannerService,
    private analyticsService: AnalyticsService,
    private deeplinkService: DeepLinkService,
    private mixPanelService: MixpanelService,
    private queryParamsService: QueryParamsService,
  ) {}

  ngOnInit(): void {
    this.platform
      .ready()
      .then(() => this.developerToolsService.initialize())
      .then(() => this.networkService.initialize())
      .then(() => this.loggerService.initialize()) // We want errors asap
      .then(() => this.mixPanelService.initialize())
      .then(() => this.initializeStates())
      .then(() => this.appState.stateReady$.next(true))
      .then(() => this.initializeServices())
      .then(() => this.initializeAuthenticatedStates())
      .then(() => this.refreshData())
      .then(() => this.appState.appReady$.next(true))
      .then(() => this.deeplinkService.initialize())
      .then(() => this.splashScreenService.hideSplashScreen())
      .catch(error => {
        if (error === 'IS_BOT') {
          this.splashScreenService.showBotSplashError();
          return;
        }
        console.error(error);
        this.splashScreenService.showSplashError();
        this.loggerService.fatal('App initialization failed', { context: JSON.stringify(error), feature: Feature.APP_INIT });

        return;
      });
  }

  private async initializeServices(): Promise<void> {
    await this.languageService.initialize();
    // We don't need to await that shelves are loaded in order to render the home page
    void this.booksService.initialize();
    this.analyticsService.initialize();
    this.queryParamsService.initialize();
    void this.bannerService.initialize(this.container);
  }

  // Don't change the order of initialization, it can lead to bugs
  private async initializeStates(): Promise<void> {
    await this.appState.initialize();
    await this.flagState.initialize();
    await this.userLocationState.initialize();
    await this.userState.initialize(new URLSearchParams(window.location.search).get('x-binu-did') || undefined);
    this.loggerService.initializeSentryUser(); // We want to get sentry user asap
    await this.defaultProjectState.initialize();
    await this.profileState.initialize();
    await this.bookReaderSettingsState.initialize();
    await this.languageState.initialize();
    await this.bookFiltersState.initialize();
    await this.searchState.initialize();
    await this.bookShelfSettingsState.initialize();
    this.appService.initialize();
  }

  private async refreshData(): Promise<void> {
    await this.profileService.refreshProfile();
  }

  private async initializeAuthenticatedStates(): Promise<void> {
    await this.activityCategories.initialize();
  }
}
