import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CanActivateFn } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { VroomTipsService } from '../../services/vroom-tips.service';

export const tipsGuard: CanActivateFn = async () => {
  const navigationService = inject(NavigationService);
  const vroomTipsService = inject(VroomTipsService);

  if (!(await firstValueFrom(vroomTipsService.shouldDisplayTips$))) {
    void navigationService.navigate('home');
    return false;
  }

  void vroomTipsService.initialize();

  return true;
};
