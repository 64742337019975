import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../../../services/app.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { fadeIn, fadeInOut } from '../../../../utils/animations';
import { BookProvider } from '../../../../providers/book.provider';
import { DefaultProjectState } from '../../../../store/default-project.state';
import { UserLocationState } from '../../../../store/user-location.state';
import { debounceTime, filter, Subject, Subscription, switchMap, take, takeUntil } from 'rxjs';
import { BooksService } from '../../../../services/books.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Book } from '../../../../models/book.model';
import { ButtonComponent } from '../../../../components/button/button.component';
import { AsyncPipe } from '@angular/common';
import { BookFiltersComponent } from '../../../../components/book-filters/book-filters.component';
import { BookGridComponent } from '../../../../components/book-shelf/book-shelf-full/components/book-grid/book-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { SearchState } from '../../../../store/search.state';
import { IonSpinner, IonInput } from '@ionic/angular/standalone';
import { SelectCategoryMobileModalPage } from '../../modals/select-category-mobile/select-category-mobile-modal.page';
import { SelectCategoryDesktopModalPage } from '../../modals/select-category-desktop/select-category-desktop-modal.page';
import { ComponentRef } from '@ionic/core';
import { ModalService } from '../../../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [fadeIn, fadeInOut],
  imports: [ButtonComponent, ReactiveFormsModule, AsyncPipe, BookFiltersComponent, BookGridComponent, TranslateModule, IonInput, IonSpinner],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('search') searchInput!: IonInput;
  @Output() searchFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public booksService: BooksService,
    public appService: AppService,
    public searchState: SearchState,
    private booksProvider: BookProvider,
    private defaultProjectState: DefaultProjectState,
    private userLocationState: UserLocationState,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
  ) {}

  private destroyed$ = new Subject<void>();

  activeSearch = false;
  isLoading = false;

  searchForm = new FormGroup({
    search: new FormControl('', [Validators.required]),
  });

  books?: Array<Book>;
  popularBooks: Array<Book> = [];

  private querySubscription?: Subscription;

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.route.queryParams),
      )
      .subscribe(params => {
        if (params['search']) {
          this.activeSearch = true;
          this.searchForm.get('search')?.setValue(params['search']);
          this.searchFocused.emit(true);
          this.ref.detectChanges();
        } else if (this.router.url === '/home') {
          this.activeSearch = false;
          this.searchFocused.emit(false);
          this.ref.detectChanges();
        }
      });

    this.booksService.initialized$
      .pipe(
        filter(initialized => initialized),
        take(1),
        switchMap(() => this.booksService.popularBooks$),
      )
      .subscribe(popularBooks => (this.popularBooks = popularBooks));

    this.searchForm
      .get('search')!
      .valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(500))
      .subscribe(value => {
        value && this.runSearch(true);
      });
  }

  focusSearch(activeSearch: boolean): void {
    this.activeSearch = activeSearch;
    this.searchFocused.emit(activeSearch);
    if (activeSearch) {
      setTimeout(() => void this.searchInput?.setFocus(), 50);
    } else {
      void this.searchInput?.getInputElement().then(inputElement => inputElement.blur());
      this.books = undefined;
      this.isLoading = false;
      this.searchForm.reset();
      this.ref.detectChanges();
    }
  }

  historySearch(search: string): void {
    void this.searchInput?.setFocus();
    this.searchForm.get('search')?.setValue(search);
    this.focusSearch(true);
    this.runSearch(false);
  }

  runSearch(saveQuery = true): void {
    const query = this.searchForm.get('search')?.value as string;
    if (query === '') {
      this.books = undefined;
      this.ref.detectChanges();
      return;
    }

    this.querySubscription && this.querySubscription.unsubscribe();
    this.isLoading = true;
    this.ref.detectChanges();

    this.querySubscription = this.booksProvider
      .searchBooks({
        query,
        projectId: this.defaultProjectState.defaultProject$.value.id,
        country: this.userLocationState.userLocation$.value.countryCode,
      })
      .subscribe({
        next: books => {
          this.books = this.searchForm.get('search')?.value === '' ? undefined : books;
          this.isLoading = false;
          this.ref.detectChanges();
        },
        error: () => {
          this.isLoading = false;
          this.ref.detectChanges();
        },
        complete: () => {
          if (saveQuery && query && !this.searchState.search$.value.history.includes(query)) {
            const searchHistory = this.searchState.search$.value.history.slice(-2);
            searchHistory.push(query);

            void this.searchState.set({ history: searchHistory });
          }
        },
      });
  }

  clearSearchHistory(): void {
    void this.searchState.set({ history: [] });
  }

  async openCategoriesModal(mobile: boolean): Promise<void> {
    const component = mobile ? SelectCategoryMobileModalPage : SelectCategoryDesktopModalPage;
    const cssClass = mobile ? 'mobile-select-category' : 'desktop-select-category';
    const breakPoints = mobile ? { breakpoints: [0, 1], initialBreakpoint: 1 } : {};

    const categoriesModal = await this.modalService.create({
      component: component as ComponentRef,
      cssClass: 'wr-modal ' + cssClass,
      ...breakPoints,
    });

    await categoriesModal.present();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
